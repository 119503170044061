import util from "lib/util"
/* eslint-disable no-undef */
export default {
  data() {
    return {
      detail: {},
      priceinfo: {},
      payway: "",
      expid: "",
      dispatchid: "",
      alipayForm: "",
      onlyView: false
    }
  },
  methods: {
    getDetail: function (showLoading) {
      if(showLoading) {
        this.loading = this.$loading(text)
      }
      this.$http.get("/apicenter/order.do?method=getOrderInfo", {
        data: {
          dispatchid: this.dispatchid,
          expid: this.expid
        }
      }).then(result => {
        let detail = result.detail
        this.detail = detail
        if (!this.onlyView && (detail.tabId !== 0 && (!detail.price || detail.paystatus != 'WAITPAY'))) {
          clearInterval(this.tCheckPay)
          showLoading && this.loading.hide()
          return this.goDetail()
        }
        detail.tabId != 0 && this.getPrice()
        if((detail.tabId == 0 || detail.price && detail.paystatus == 'WAITPAY') &&  this.$store.state.globalData.isWechat) {
          util.loadScript("//res.wx.qq.com/open/js/jweixin-1.0.0.js", function() {
            showLoading && this.loading.hide()
          })
        } else {
          showLoading && this.loading.hide()
        }
      }).catch(res=> {
        this.$toast(res.message)
        showLoading && this.loading.hide()
      })
    },
    getPrice() {
      this.$http.get("/apicenter/order.do?method=querypriceinfo", {
        data: {
          expid: this.expid
        },
      }).then(result => {
        this.priceinfo = result.data || {}
      })
    },
    pay: function() {
      var api = this.detail.tabId == 0 ? "prePay" : "disparityPay"
      var backPath = process.env.NODE_ENV != 'development' ? "/mexpress/" : "/"
      var backUrl = `${backPath}dispatch/detail?expid=${this.expid}&dispatchid=${this.dispatchid}`
      if (!this.payway) {
        return this.$toast("请选择支付方式")
      }
      this.$http.post("/apicenter/order.do?method=" + api, {
        data: {
          expid: this.expid,
          tradetype: this.payway == 'WEIXIN' && typeof (wx) != "undefined" ? "JSAPI" : "MWEB",
          payway: this.payway,
          quitUrl: encodeURIComponent(backUrl)
        }
      }).then(result => {
        if (this.payway === 'WEIXIN') {
          if (typeof (wx) != "undefined") { //微信支付
            this.jsApiParameters = JSON.parse(result.weixinpayReqStr)
            this.callpay()
          } else {
            if (result.mweb_url) {
              this.checkPay()
              location.href = result.mweb_url
            } else {
              this.$toast('订单错误，请刷新重试')
            }
          }
        } else {
          this.alipayForm = result.alipayReqStr
          this.$nextTick(function() {
            document.forms.punchout_form.submit()
          })
        }
      })
    },
    callpay: function () {
      var f = this.wxPay
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', f, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', f)
          document.attachEvent('onWeixinJSBridgeReady', f)
        }
      } else {
        f()
      }
    },
    wxPay: function () {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        this.jsApiParameters,
        (res) => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            this.goDetail()
          } else if (res.err_msg == "get_brand_wcpay_request:fail") {
            this.$toast("支付失败，请稍后再试")
          } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            this.$toast("支付失败，请稍后再试")
          }
        }
      );
    },
    checkPay: function() {
      var count = 0
      this.tCheckPay = setInterval(() => {
        count++
        if(count > 60) {
          clearInterval(this.tCheckPay)
        }
        this.getDetail()
      }, 2000)
    },
    goDetail: function() {
      let lastpage = util.getLastPage()
      let sources = ['detail,', 'orderList']
      if(sources.includes(this.source) || sources.includes(lastpage)) {//从详情或者历史记录进来
        this.$router.go(-1)
      } else {//其他渠道进来，如短信
        /* this.navigator.replace({
          name: "dispatchDetail",
          query: {
            dispatchid: this.dispatchid,
            expid: this.expid
          }
        }) */
        this.navigator.replace({
          name: "dispatchDetailIndex",
          query: {
            expid: item.expid,
            dispatchid: item.dispatchId
          }
        })
      }
    },
    init() {
      this.login(() => {
        this.getDetail()
      }, () => {
        this.goLogin()
      }) 
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.expid = to.query.expid
      vm.dispatchid = to.query.dispatchid
      vm.source = to.params.source
      vm.onlyView = to.query.view == 1
      vm.init()
    })
  },
  beforeDestroy() {
    clearInterval(this.tCheckPay)
  }
}